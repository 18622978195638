<template>
    <v-container fill-height>
        <v-fab-transition>
            <v-row align="center" justify="center" v-show="open" class="text-center">
                <v-spacer v-show="!isMobile"></v-spacer>

                <v-col sm="12">
                    <v-card-subtitle>
                        <span class="subtitle-custom"> {{ $t('home.hello') }} </span>
                    </v-card-subtitle>

                    <h1 class="text-h1 font-weight-bold mb-3 boubou--text">Bouzid Krita</h1>
                    <h2 class="text-h4 heading font-weight-regular grey--text text--lighten-3 font-weight-bold">
                        {{ $t('home.job') }}
                    </h2>

                    <v-card-text class="text-h6">
                        <p>
                            {{ $t('home.passion') }}

                            <br />
                        </p>
                    </v-card-text>

                    <v-btn v-for="(social, i) in socials" :key="i" :color="social.color" class="white--text" fab icon large
                        @click="social.action">
                        <v-icon>{{ social.icon }}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-fab-transition>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'HelloWorld',

    data: () => ({
        open: false,
    }),
    computed: {
        ...mapState({
            socials: (state) => state.socials,
        }),
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },

    mounted() {
        this.open = true;
    },

    beforeDestroy() {
        this.open = false;
    },
};
</script>
