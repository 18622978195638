<template>
  <div>
    <v-app-bar app color="#0F0F0F">
      <v-app-bar-nav-icon
        v-if="isMobile || isTablet"
        @click.stop="drawer = !drawer"
        color="white"
      ></v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        v-else
        @click.stop="mini = !mini"
        color="white"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        v-if="mini || isMobile || isTablet"
        class="boubou--text font-weight-bold"
        >Bouzid Krita</v-toolbar-title
      >
      <v-spacer></v-spacer>

      <v-btn text @click="changeLanguage" class="white--text">
        <v-icon>mdi-translate </v-icon>
        {{ languageText }}
        <v-img width="20" :src="flagOfLanguage" class="ml-2" />
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      :mini-variant="mini"
      :temporary="isMobile || isTablet"
      color="#0F0F0F"
      dark
      class="d-flex flex-column"
    >
      <v-list-item class="px-2 py-2 text-center">
        <v-list-item-avatar v-if="mini" class="boubou--text font-weight-bold">
          BK
        </v-list-item-avatar>

        <v-list-item-title class="boubou--text font-weight-bold"
          >Bouzid Krita</v-list-item-title
        >
        <v-spacer></v-spacer>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item
          v-for="route in routes"
          :key="route.path"
          :to="route.path"
          class="white--text"
          :class="activeClass(route.path) ? 'boubou' : ''"
        >
          <v-list-item-icon>
            <v-icon>{{ route.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t(`route.${route.name}`)
            }}</v-list-item-title>
            <v-list-item-title> </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div
          :class="!mini ? 'd-flex flex-column align-center' : 'align-start '"
        >
          <v-card-subtitle class="boubou--text text-subtitle-2">
            © {{ year }} {{ mini ? "BK" : "Bouzid KRITA" }}
          </v-card-subtitle>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      routes: this.$router.options.routes,
      mini: false,
    };
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale === "en" ? "fr" : "en";
      localStorage.setItem("language", this.$i18n.locale);
    },
    setLanguage() {
      if (localStorage.getItem("language")) {
        this.$i18n.locale = localStorage.getItem("language");
      }
    },
    drawerMobile() {
      if (this.$vuetify.breakpoint.xs) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isTablet() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    languageText() {
      if (!this.isMobile)
        return this.$i18n.locale === "fr" ? "Français" : "English";
      else return this.$i18n.locale === "fr" ? "fr" : "en";
    },
    flagOfLanguage() {
      return this.$i18n.locale === "fr"
        ? require("../assets/img/Flag_of_France.png")
        : require("../assets/img/Flag_of_England.png");
    },
    year() {
      return new Date().getFullYear();
    },
    activeClass() {
      return (path) => {
        return this.$route.path === path;
      };
    },
  },
  mounted() {
    this.setLanguage();
    this.drawerMobile();
  },
};
</script>

<style lang="scss" scoped>
/* v list item active no background  */
.custom-active-class :deep(.v-list-item__overlay) {
  opacity: 0;
}
</style>
