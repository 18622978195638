import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import i18n from "@/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    icon: "mdi-home",
    component: HomeView,
    meta: {
      title: i18n.t("route.Home"),
    },
  },
  {
    path: "/about",
    name: "About",
    icon: "mdi-account-question",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: i18n.t("route.About"),
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    icon: "mdi-folder",
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "../views/PortfolioView.vue"),
    meta: {
      title: i18n.t("route.Portfolio"),
    },
  },
  {
    path: "/contact",
    name: "Contact",
    icon: "mdi-email",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    meta: {
      title: i18n.t("route.Contact"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Bouzid Krita`;
  next();
});

export default router;
