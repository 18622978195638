<template>
  <v-app class="background">
    <v-main>
      <SideBar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
  components: { SideBar },
  name: "App",
};
</script>
<style lang="scss">
.card-hoverable {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
}

.subtitle-custom {
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  font-size: 1.2rem;
}
</style>
