import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    diplomes: [
      {
        title: "about.diplomes.concepteur.title",
        logo: require("../assets/img/logo/afpa.png"),
        from: "AFPA",
        time: "2021",
        color: "boubou",
        message: "about.diplomes.concepteur.message",
      },
      {
        title: "about.diplomes.designer.title",
        logo: require("../assets/img/logo/etapedesign.jpg"),
        from: "L'Étape Design (Innovation Factory)",
        time: "2019",
        color: "boubou",
        message: "about.diplomes.designer.message",
      },
    ],
    experiences: [
      {
        title: "Développeur Front-end",
        logo: require("../assets/img/logo/bienici.png"),
        from: "Bien ici",
        time: "mars 2023 à aujourd’hui",
        projectResume: "cardexperience.bienici.projectresume",
        technologies: ["Vue.js", "Vuetify", "Node.js", "Git", "API REST"],
        color: "boubou",
      },
      {
        title: "Développeur Front-end",
        logo: require("../assets/img/logo/habiteo.png"),
        from: "Habiteo",
        time: "déc. 2022 - mars 2023",
        projectResume: "cardexperience.habiteo.projectresume",
        technologies: ["Vue.js", "Vuetify", "Node.js", "Git", "API REST"],
        color: "boubou",
      },
      {
        title: "Développeur Front-end",
        logo: require("../assets/img/logo/mercateam.png"),
        from: "Mercateam",
        time: "févr. 2022 - déc. 2022",
        projectResume: "cardexperience.mercateam.projectresume",
        technologies: [
          "Vue.js",
          "Vuetify",
          "Node.js",
          "Docker",
          "Git",
          "Cypress",
        ],
        color: "boubou",
      },
    ],
    works: [
      {
        src: require("../assets/img/portfolio/wooyoungwoo.png"),
        thumb: require("../assets/img/portfolio/wooyoungwoo.png"),
        title: "WooYoung Woo",
        category: {
          fr: "site web",
          en: "website",
        },
        description: "works.wooyoungwoo.description",
        technologies: ["Wordpress", "Divi"],
        link: "https://wooyoungwoobybouzid.netlify.app/",
      },
      {
        src: require("../assets/img/portfolio/pokedex.png"),
        thumb: require("../assets/img/portfolio/pokedex.png"),
        title: "Pokedex",
        category: {
          fr: "application",
          en: "application",
        },
        description: "works.pokedex.description",
        technologies: ["Vue.js", "Vuetify", "Node.js"],
        link: "https://github.com/bouboudev/pokedex",
      },
      {
        src: require("../assets/img/portfolio/electricchat.png"),
        thumb: require("../assets/img/portfolio/electricchat.png"),
        title: "Electric chat",
        category: {
          fr: "application",
          en: "application",
        },
        description: "works.electricchat.description",
        technologies: ["Vue.js", "Firebase", "Node.js"],
        link: "https://github.com/bouboudev/electricvuechat",
      },
      {
        src: require("../assets/img/portfolio/coffeecreative.png"),
        thumb: require("../assets/img/portfolio/coffeecreative.png"),
        title: "Coffee creative",
        category: {
          fr: "site web",
          en: "website",
        },
        description: "works.coffeecreative.description",

        technologies: ["Html", "Css", "Javascript", "Gsap", "BARBA.js"],
        link: "https://github.com/bouboudev/coffee_creative",
      },
      {
        src: require("../assets/img/portfolio/dbanime.png"),
        thumb: require("../assets/img/portfolio/dbanime.png"),
        title: "The anime database",
        category: {
          fr: "site web",
          en: "website",
        },
        description: "works.theanimedatabase.description",

        technologies: ["Vue.js", "Node.js", "Api rest"],
        link: "https://github.com/bouboudev/anime-database-vue",
      },
      {
        src: require("../assets/img/portfolio/dbmovie.png"),
        thumb: require("../assets/img/portfolio/dbmovie.png"),
        title: "Database movie",
        category: {
          fr: "site web",
          en: "website",
        },
        description: "works.databasemovie.description",
        technologies: ["Vue.js", "Node.js", "Api rest"],
        link: "https://github.com/bouboudev/movie-db-vue",
      },
      {
        src: require("../assets/img/portfolio/lofimusic.png"),
        thumb: require("../assets/img/portfolio/lofimusic.png"),
        title: "Lofi music",
        category: {
          fr: "application",
          en: "application",
        },
        description: "works.lofimusic.description",
        technologies: ["Vue.js", "Node.js"],
        link: "https://github.com/bouboudev/lofi-music",
      },
    ],
    socials: [
      {
        icon: "mdi-github",
        color: "boubou",
        action: () => {
          window.open("https://github.com/bouboudev", "_blank");
        },
      },
      {
        icon: "mdi-linkedin",
        color: "boubou",
        action: () => {
          window.open("https://www.linkedin.com/in/bouzidkrita/", "_blank");
        },
      },
    ],
  },
  getters: {
    diplomes: (state) => state.diplomes,
  },
  mutations: {},
  actions: {},
  modules: {},
});
