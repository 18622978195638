import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        boubou: "#DEC79B",
        primary: "#DEC79B",
        text: "#DEC79B",
        dark: "#0F0F0F",
        background: "#0F0F0F",
      },
    },
    dark: true,
  },
});
